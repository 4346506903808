$color-mandala: #330080;

.c-featurePager__container {
    margin: 1rem 0 -1.5rem;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 0 1.5rem;
    color: #000;
    letter-spacing: 0.035rem;

    ~ .c-featurePager__container,
    &.bottom {
        margin-top: 3rem;
    }
    .c-featurePager__resultSummary {
        white-space: nowrap;
        margin: 0 auto 1.5rem;
        padding-left: 0;
        padding-right: 1rem;
        /*font-size: 1.55rem;*/
        font-style: italic;
        .start,
        .end {
            display: inline-block;
            margin-left: 0;
            margin-right: 4px;
            font-style: normal;
            font-weight: bold;
            color: #000;
            padding-right: 0.2rem;
        }
        .start {
            padding-left: 0.5rem;
            padding-right: 0;
        }
        .total {
            font-weight: bold;
            /*font-size: 1.5rem;*/
            padding: 0.15rem 0 0.2rem 0;
            border-radius: 1.5rem;
        }
    }
    .c-featurePager__navButtons {
        white-space: nowrap;
        margin: 0 auto 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        /*font-size: 1rem;*/
        position: relative;
        top: -0.2rem;

        .icon {
            margin: 0 .5rem 0 0;
            padding: 0.7rem 0.75rem 0.6rem 1rem;
            color: #333;
            background-color: transparent;
            border-radius: 0.75rem;
           /* border: 0.4rem solid #ccc;*/
            &:hover {
                opacity: 1;
                background-color: #fff;
                border-color: #929ae4;
            }
            &:active {
                border-style: double;
            }
        }
        .icon:last-child {
            padding-right: 1rem;
        }
        .icon:last-child::before {
            position: relative;
            left: 0.3rem;
        }
        .icon:first-child {
            padding-left: 1rem;
        }
        .icon:nth-of-type(2) {
            margin-right: 0.25rem;
        }
        .u-icon__arrow3-right::before,
        .shanticon-arrow3-right:before {
            position: relative;
            left: 0.25rem;
        }
        span input {
            //  line-height: 1.15;
            //  width: 5.5rem;
            margin-right: 0.85rem;
            margin-left: 0.85rem;
            text-align: center;
            border-color: $color-mandala;
            border-style: solid;
            border-radius: 0.35rem;
            border-width: 0.35rem 0.1rem;
            padding: 0 !important;
            opacity: 0.9;
            color: #000;
            line-height: 1.25 !important;
            &:hover {
                opacity: 1;
            }
        }
        .c-pager__counterWrapper {
            margin-right: 1rem;
            margin-left: 1.5rem;
            margin-bottom: 1.5rem;
            font-style: italic;
            /*font-size: 1.5rem;*/
            span {
                padding: 0;
                margin: 0;
                width: 15px;
            }

            .c-pager__counterMax {
                padding: 0 1rem;
                font-weight: bold;
                /*font-size: 1.35rem;*/
            }
        }
    }
    .c-featurePager__itemCount {
        white-space: nowrap;
        margin: -0.35rem auto 1.5rem;
        padding-left: 0;
        padding-right: 1rem;
       /* font-size: 1.6rem;*/
        font-style: italic;

        .react-numeric-input {
            padding-right: 2rem;
            padding-left: 0.5rem;
        }
        input {
            margin-left: 0.1rem;
            padding: 0 !important;
            text-align: center;
            line-height: 1.25 !important;
            width: 2.5rem;
            border-radius: 0.35rem !important;
            border-style: solid;
            border-color: $color-mandala !important;
            border-width: 0.35rem 0.1rem !important;
            opacity: 0.9;
            color: #000;
            &:hover {
                opacity: 1;
            }
        }
        .react-numeric-input b {
            position: absolute;
            right: -0.7rem !important;
            width: 2rem !important;
            background-color: #fff !important;
            border-color: #4d59ca !important;
            max-height: 15px;
            i {
                /*font-size: 2rem !important;*/
            }
        }
        .react-numeric-input b:first-of-type {
            top: -0rem !important;
            border-radius: 0.5rem 0.5rem 0 0 !important;
            border-width: 0.25rem 0.05rem 0.1rem !important;
        }
        .react-numeric-input b:last-child {
            bottom: -0.25rem !important;
            border-radius: 0 0.05rem 0.5rem 0.5rem !important;
            border-width: 0 0.05rem 0.25rem !important;
        }
        .react-numeric-input b:hover i {
            /*font-size: 2.35rem !important;*/
        }
    }
}

.c-view__wrapper.list .c-featurePager__container {
    padding: 0 2rem 0 2.5rem;
}
.c-view__wrapper.gallery .c-featurePager__container {
    padding: 0 1.5rem 0 1.5rem !important;
}

/*
.c-view__wrapper.gallery .c-featurePager__container ~ .c-featurePager__container {
    width: 100%;
}
 */

.c-view__wrapper.gallery .react-photo-gallery--gallery {
    margin-bottom: 3rem !important;
}
