.react-tiny-popover-container {
  z-index:100;
}

#c-text-reader {
  display: flex;
  flex-flow: column;
  height: 88vh;

}

#c-text-reader_head {
  /*margin: 0 0 1rem 0rem;*/
  /*border: 3px inset lightgray; */
  border-bottom: 3px ridge #bcbcbc;
  background-color: #fbfbfb;
  display: block;
  max-height: 80px;
  max-width: 100%;
  padding: 0.2rem;
  .row {
    margin: 0;
    padding: 0 1rem 0 0;
  }
}

.text-reader-prev-btn {
  visibility: hidden;
}

/** Text Reader Info Row in header **/
#c-text-reader_head #c-text-reader_info {
  .collname {
    line-height: 1.2rem;
  }
}

/** Text Reader Nav in header **/
#c-text-reader.pages #c-text-reader_head #c-text-reader_nav {
  padding-top: 0.5rem;
}

#c-text-reader_head #c-text-reader_nav {
  /*padding-top: 0.25rem;*/

  /** Text pager **/
  .text-pager {
    display: flex;
    & > * {
      height: 30px;
      line-height: 30px;
    }

    .prevbtn, .nextbtn {
      padding-top: 0;
      height: 1.5rem;
      margin-top: -0.15rem;
    }

  } /** End of pager **/

  .form-group {
    display: flex;
    padding: 0 0.25rem;
    font-size: 90%;

    /* For the page type toggle and following icons */
    &.pgtype {
      /* max-width: 200px!important; (took out 2023-04-07 for dkcw) */

      .pagenumtoggle, .linenumtoggle, .backlink {
        display: inline-block;
        margin: 0 0.5rem;
        color: green;

        width: 17px;

        a {
          width: 20px;
        }
      }

      .pagenumtoggle.on a, .linenumtoggle.on a {
        font-weight: bold;
      }

      .pagenumtoggle.off a, .linenumtoggle.off a {
        font-weight: normal;
        color: #AAA;

        &:after {
          display:inline-block;
          content:'/';
          position: relative;
          left: -0.5rem;
          color: #454590;
        }
      }
    }

    input {
      font-size: 100%;
    }
  }


  .form-label {
    white-space: nowrap;
    margin-right: 0.25rem;
    &:after {
      display: none;
    }
  }

  .form-control {
    width: 3.5rem;
    height: 1.5rem;
    margin-top: 0.25rem;
  }

  .section-list.dropdown .chapid {
    padding-right: 0.25rem;
  }

  a:not(href) {
    cursor: pointer;
  }

  a.disabled {
    color: lightgray !important;
    pointer-events: none !important;
  }
  .sectscope {
    display: inline-block;
    line-height: 2rem;
    white-space: nowrap;
    margin-left: 1rem;
    overflow: hidden !important;
    height: 2rem;
    text-overflow: ellipsis !important;
    max-width: 340px;
  }

  .backlink {
    display: inline-block;
    line-height: 2rem;
    white-space: nowrap;
  }

  .dropdown-menu.show {
    overflow-y: scroll;
    max-height: 90vh;
  }

  .pgtype .dropdown-item.disabled {
    color: #bbb;
  }

  button.dropdown-toggle {
    color: #454590;
    font-size: 1rem;

    &:after {
      vertical-align: 0.15em;
    }
  }
  a, button {
    color: #454590;

    &:hover {
      color: #007bff;
      text-decoration: none;
      border-bottom: none;
    }
  }

  .form-group.pgtype {
    label {
      display: inline-block;
      margin-top: 0.25rem;
      &:after {
        content: '';
      }
    }
    .dropdown.btn-group {
      margin-top: -0.25rem;

      & > * {
        font-size: 100%;
      }
    }
  }

  div.backlink {
    margin-left: -1.5rem;
    a {
      font-size: 0.9rem;
    }
  }
} /* End of Text Reader Nav in Header */

.c-text-view {
  font-size: 130%;
  display: block;
  height: 90vh;
  overflow-y: scroll;
  padding: 1rem 1rem;

  .c-text-page {
    display: inline;

    .hl {
      font-size: inherit;
    }
  }
  .c-text-page.current {
    font-weight: bold;
  }
}


.l-frame-toc .text-toc span.toc-pgn {
  font-size: 90%;
}

.milestone {
  font-size: 1rem;
  &:before {
    content:'[';
  }
  &:after {
    content: ']';
  }
  &.page {
    color: #736fe4;
  }
  &.line {
    color: #232D4B90;
  }
}

.hidepages .milestone.page, .hidepages .milestone.digpage {
  display: none;
  visibility: hidden;
}

.hidelines .milestone.line, .hidelines .milestone.digline {
  display: none;
  visibility: hidden;
}

.milestone.digpage, .milestone.digline {
  color: #a7a7a7;
}

#c-text-page-scans {
  .c-text-scan div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .img-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    max-height: 290px;
  }

  .img-wrapper img {
    width: 100%;
  }

  .below-img {
    padding: 1rem;
    width: 100%;
    text-align: center;

    .icon:before {
      font-size: 60%;
      vertical-align: super;
    }
  }
}

.c-text-view.bo {
  h1  {
    font-family: "Jomolhari", "Jomlhari-ID", "Tibetan Machine Uni", Kokonor, sans-serif;
    font-size: 1.75rem;
    font-weight: normal;

    span.num {
      font-weight: bold !important;
    }
  }
}

#cattoc-tab-tabpane-texttoc .selected {
  &>.toc-icon, &>.chapter {
    font-weight: bold;
  }
}
