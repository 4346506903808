

.close-simple-pop {
  position: absolute;
  top: 1px;
  right: 7px;
  cursor: pointer;
}

.highlight, .hl {
  font-size: 110%;
  color: red;
  font-weight: bolder;
}

.pntr {
  cursor: pointer;

  &.bold:hover {
    font-weight: bold;
  }
  &.line:hover {
    border-bottom: thin solid #000;
  }
}

div.infinite-load-btn {
  button {
    font-size: 50%;
    border-radius: 20px;

    &.finis {
      border: none;
      background: transparent;

    }
  }
}

.error {
  border: thin solid red;
  background-color: rgba(255, 0, 0, 0.37);
}

.hidden {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

.smaller {
  font-size: 90%;
}

/** Apparatus styles **/
span.variant {
  color: #ea7c44;
}
