/** SCSS for Catalog pages */
@import 'variables.scss';

/** TOC Styles */

.l-frame-toc .c-toc {
  li.volume {
    margin-top: 0.5rem;

    ul.textlist {
      margin-top: 0.25rem;
    }
  }

  span.toc-toggle {
    display: inline-block;
    margin-right: 0.3rem;
  }

  span.toc-dox-range {
    font-size: 90%;
    font-weight: normal !important;
    color: grey;

    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
}

/** Text TOC Styles **/
.l-frame-toc .text-toc {
  ul {
    padding-inline-start: 20px;
    .toc-icon {
      padding-right: 0.25rem;
    }
    .icon-square {
      font-size: 80%;
      color: #333;
    }
    .icon-square.hidden {
      color: transparent !important;
    }
    li {
      .closed {
        display: none;
      }
      a {
        cursor: pointer;

        &:hover {
          text-decoration: none;
          border-bottom: none;
        }
      }
    }
  }
}

/** Bibl Records */
.c-bibl.row, .c-dox-record {
  margin: 0;
  padding: 1rem 0.5rem;
  overflow-y: scroll;
  color: $text-color;
  font-size: 15PX;

  .row {
    margin: 0;
    width: 100%;
  }

  .bibl-info {
    min-width: 75%;
  }

  h1, h2, h3, h4, h5 {
    font-family: verdana,arial,sans-serif;
    color: $header-color;
  }

  h2 {
    font-size: 110%;
  }

  h3 {
    font-size: 110%;
  }

  h4 {
    font-size: 100%;
  }
  .c-bibl_head .bo {
    font-size: 100%;
  }

  .bo {
    font-size: 140%;
  }

  span.toc-pgn-2:before {
    content: '-';
  }
}

.c-dox-record {
  margin-left: 1rem;
}


.c-bibl .c-text-sections {
  ul.section-list {
    list-style-type: none;
    font-size: 120%;
  }

  li.parent {
    margin-left: -0.95rem;
  }

  a.link {
    color: $link-color;
    &:hover {
      color: $link-hover;
      cursor: pointer;
      text-decoration: none;
      border: none;
    }
  }
}
.c-bibl-nav {
  border: 3px outset #888;
  background-color: $nav-bgcolor;
  padding: 1rem;
  position: fixed;
  /* float: right;*/

  ul {
    margin: 0 0 0 1rem;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;

    a:hover {
      cursor: pointer;
      color: $highlight-color;
    }
    &.selected a {
      color: $highlight-color;
    }
  }
}

.c-bibl_head {
  text-align: center;
  display: block;
  font-size: 1.75rem;
  .biblid {
    display: inline-block;
    font-size: 60%;
    vertical-align: text-top;
    padding-top: 0.5rem;
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
}

.c-bibl_toc {
  margin-bottom: 1rem;
  .sections {
    .h5 {
      font-size: 110%;
      text-decoration: underline;
    }

    .row {
      margin-left: 0;

      .chaptitle {
        display: inline-block;
        margin-left: 1rem;
        text-indent: -1rem;
      }
    }

    .body, .back {
      margin-top: 1rem;

      .headrow {
        width: 101%; // corrects alignment for body headers when next to bibl record nav block
      }
    }
  }
}

.c-bibl_toc.dynamic {
  ul {
    padding-inline-start: 18px;
    list-style-type: none;

    .toc-icon:not(.end-leaf) {
      cursor: pointer;
    }

    li.lvl-1 > .toc-icon > .end-leaf {
      display: none;
    }

    li .end-leaf {
      font-size: 80%;
      color: $med-gray;
      display: inline-block;
      margin-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }
  li {
    list-style-type: none;
  }
  a.link {
    color: $toc-item-color;
    &:hover {
      color: $highlight-color;
      cursor: pointer;
      text-decoration: none;
      border: none;
    }
  }
}

.bibl__page--vol {
  display: inline-block;
  margin-right: 0.25rem;
  text-transform: capitalize;
  &:after {
    content: ',';
  }
}

#l-doxbib-header {
  font-size: 100%;
  background-color: transparent;
  padding-left: 0;
  margin-left:-12px;
}

/** Master **/
/** Corresp Table **/
table.parallel-texts {
  max-width: 800px;
}

/** Dox categories **/
.c-bibl_inteldesc .master-dox {
  .dox-path {
    display: inline-block;
    a.dox-item {
      display: inline-block;
      padding-right: 0.25rem;
    }
    a.dox-item:after {
      content: ">";
      margin-left: 0.25rem;
      display: inline-block;
    }
    a.dox-item:last-child:after {
      display: none;
    }
  }

  select.lang-select {
    border-color: #7c7c7c70;
    border-radius: 20px;
    color: #7c7c7c;
    font-size: 75%;
    padding: 1px;
    margin-right: 0.5rem;
  }
}


/* For embedded descriptions of doxcat from collab */
div.publicview, #rwiki_content {
  margin-top: 0;
  padding-top: 0;
}
#rwiki_container {
  padding: 0.5rem 1rem 0 0;
  /*border: thick #aaa double; */
  .portletBody {
    color: white;  /* Hides stray text at top of portletBody */

    #rwiki_content {
      color: #444;
      margin-top: -2rem; /* Covers hidden text at top of portletBody */
    }
  }

  .rwikiRenderBody > .rwiki_RenderedContent > h3.heading-h6 {
    display: none;
  }
}

/** General Styles **/
label {
  font-weight: bold;
  margin-bottom: 0 !important;
  text-transform: capitalize !important;
  &:after {
    content: ':';
    margin-right: 0.3rem;
  }
}

ul ul {
  margin-top: -0.2rem;
}


.bo {
  font-family: "Jomolhari", "Jomlhari-ID", "Tibetan Machine Uni", Kokonor, sans-serif;
  font-size: 120%;
  vertical-align: middle;
}

.en {
  font-size: 100%;
}

.bo .en {
  font-size: 1rem;
}

.closed {
  display: none;
}

.san {
  font-family: "Kokila", "Kohinoor Devanagari", "Arial Unicode MS", "Adelle Sans Devanagari",
                "Tiro Devanagari Sanskrit", "Devanagari Sangam MN", sans-serif;
}

h1, h2, h3, h4, h5, h6, h7 {
  .bo {
    font-weight: normal;
  }
}

.parens {
  display: inline-block;
  &:before {
    margin-left: 0.25rem;
    content: '(';
  }
  &:after {
    content: ')';
  }
}
/* This is too general
.active {
  font-weight: bold;
}
*/
