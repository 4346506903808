

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;400;700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;400;700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jomolhari&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import '../../node_modules/bootstrap/scss/bootstrap';    // Import Bootstrap and its default variables
@import 'fonts/shanticon/style.css';                      // Import Shanticon styles
@import './variables.scss';
@import './elements.scss';
@import './structure.scss';
@import './search.scss';


