/** SCSS Variables used in THL Catalog React app **/
$sans-font: "Trebuchet MS", Helvetica, sans-serif; // "Helvetica Neue", Helvetica, Arial, sans-serif;
$serif-font: Garamond, serif;
$base-fontsize: 12pt;
$base-color: #333333;
$base-bgcolor: #fcfcfc;
$banner-bgcolor: #F9FAFA;
$banner-color: rgb(59,59,59);  // rgba(255,255,255,0.5);
$searchbox-color: rgba(77,89,201,.8);
$shadow-border: 0px 3px 3px #dfdfdf;
