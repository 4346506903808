@import './variables.scss';

#search-box .nav-search-form {
  display: flex;
  flex-direction: row;
  input.cat-search {
    font-size: 1rem;
    font-weight: normal;
    padding: 0.6rem 0 0 0.25rem;
    border: none;
    border-bottom: .1rem solid $banner-color;
    border-radius: 0;
    margin: -1rem 1rem 0 0;
    width: 15rem;
    transition: width .5s,border .35s;
    color: $banner-color;
    box-shadow: none;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $searchbox-color;
      font-style: italic;
      font-weight: normal;
      font-size: 1.2rem;
      font-family: "GB Garamond",serif;
      letter-spacing: .0125rem;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      -webkit-font-smoothing: antialiased;
    }
    &:focus {
      &::-webkit-input-placeholder { color:transparent; }
      width: 20rem;
    }
  }

  .f-wyl-cb {
    margin: 1rem 1rem 0 0;
    label {
      font-weight: normal;
      font-size: 0.75rem;
      margin-left: -0.9rem;
      padding: 0rem 1.5rem 0 0;
      white-space: nowrap;
      vertical-align: text-bottom;
      color: $banner-color;
    }
    input.form-check-input {
      width: 10px;
    }
  }

  button.submit-btn {
    display: none;
  }

  .search-and-back {
    padding-top: .5rem;
    .back {
      a {
        font-size: 80%;
      }
    }
    &.search-page {
      .back a { display: none; }
    }
  }
}

#srch-results {

  & > h1 {
    margin: 2rem 0 -0.5rem 1rem;
  }
  #c-search-ctls {
    display: flex;
    flex-flow: row;
    /* border: thick solid purple; */
    height: 50px;
    width: 30%;
    float: right;
    margin-top: -30px;
  }

  #c-search-ctls > * {
    flex: 1;
    text-align: center;
  }

  ul.results {
    margin: 0.5rem;
    padding: 1rem 0.5rem 1rem 1.5rem;
    border: thin solid #777;
    border-radius: 20px;

    li {
      line-height: 2rem;
    }

    ol.hitlist {
      counter-reset: list;

      & > li {
        list-style: none;
      }

      & > li:before {
        content:  "[" counter(list, decimal) "] ";
        counter-increment: list;
      }

    }
  }
}


