
$header-color: #46727C;
$toc-item-color: #454590;
$text-color: #33545B;
$highlight-color: #983d3d !important; /* indianred !important; */

$nav-bgcolor: #edededff;

$link-color: #144379;
$link-hover: #007BFF;


$med-gray: #C1C5C4;
