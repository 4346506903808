$max-content-height: calc(100% - 56px);

.l-catalog-frame {
  margin: 0 !important;
  min-height: 90vh;
  max-height: $max-content-height;
  max-width: unset !important;

  .Pane.vertical {
    overflow-y: scroll;
  }

  .l-frame-content {
    background-color: #fefefe;
    min-height: 100%;
  }

  .Pane2 {
    background-color: #eeeeee;
  }

  * .l-frame-wrapper {
    /* padding: 1rem 1.5rem; */
    margin-top: 1rem;
    max-height: $max-content-height;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  .c-dox-desc {
    margin: 0 2rem 0 0;
  }

  img[title='external link'] {
    display: none;
  }
}

/** Styles for catalog summary page **/
.l-catalog-frame {
  .summary {
    margin: 1.5rem;

    ul.edlist {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li.edlink {
        display: inline-block;
        list-style-type: none;
        &:after {
          content: ', ';
          padding-right: 0.25rem;
        }

        &:last-of-type:after {
          content: '';
          padding-right: 0;
        }
      }
    }

    .collection.editions {
      .edsummary {
        display: none;
      }
    }
  }
}

/** TOC Styles **/
.c-toc {
  .c-toc-toggle {
    padding-left: 2rem;
    .toc-type-btn, .toc-type-btn.active {
      background: transparent !important;
      border: none;
      color: #212529;
      padding: 0;
      margin: 0 1rem 0 0;
      input {
        margin-right: 0.3rem;
      }
    }
  }


  ul {
    list-style-type: none;
    padding-left: 0;
  }

  ul ul li {
    list-style: disc !important;
    margin-left: 3rem;
  }

  ul a {
    color: #454590;

    &:hover {
      color: #3030e3;
      text-decoration: none;
    }

    * {
      cursor: pointer;
    }
  }

  /** Just Vol TOC **/

} /** End of TOC Styles **/

.active .c-toc  {
  font-weight: normal;
}
.c-toc.vol {
  li.volume.selected .vol-label {
    font-weight: bold;
  }
}

$doxindent: 0.65rem;

.l-frame-toc {
  padding: 1rem;
}

.c-toc {
  div[class*="lvl-"] {
    padding-left: $doxindent;
  }
  .toc-text.selected  {
    font-weight: bold;
    background-color: antiquewhite;
  }
}

.c-toc-doxcat {
  line-height: 2.25rem;
  &.selected > .toggle-link span {
    font-weight: bold;
  }
  .dox-label {
    white-space: nowrap;
  }
  .toggle-icon {
    margin-right: 0.2rem;
  }
}


.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
