/* Block Styles */
.c-text-view {
  blockquote.q, blockquote.quote {
    margin-left: 1rem;
  }

  .modal-dialog {
    max-width: 800px;
  }

  button.footnote-ref {
    border: none;
    font-size: 80%;
    padding: 0 2px;
    vertical-align: super;
  }
}

/* Inline Styles */
.c-text-view {
  ul.verse {
    list-style-type: none;
  }

  span.title {
    &.m, &.j {
      color: #8a437c;
    }
  }

  span.person {
    color:green;
  }

  span.place {
    color:blue;
  }

  span.title, span.person, span.place, span.milestone {
    &:hover {
      font-weight: bold;
    }
  }
}

