@import './variables.scss';

body {
  font-family: $sans-font;
  font-size: $base-fontsize;
  padding-top: 3rem;
  color: $base-color;
  background-color: $base-bgcolor;
  height: 100vh;
}

h1, h2, h3, h4, h5, h6, h7 {
  font-family: $sans-font;
  color: #222222;
  font-weight: bold;
  letter-spacing: 0.25px;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.3rem;
}

h5, h6, h7 {
  font-size: 1.2rem;
}

h2, h4, h6 {
  font-style: italic;
}

main#root {
  display: flex;
  padding:0;
  margin: 0;
  max-height: 100vh !important;
}

main#root > header {
  flex: 0 1 auto;

  #catalog-nav {
    max-height: 60px;
    background-color: $banner-bgcolor;
    padding: 0.5rem;
    -webkit-box-shadow: $shadow-border;
    -moz-box-shadow: $shadow-border;
    box-shadow: $shadow-border;

    .navbar-brand {
      display: inline-block;
      background-color: #fff;
      max-height: 60px;
    }

    .site-title {
      margin: 0 1rem 0 -0.5rem;
      padding: 0.5rem 0 0 0;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2;
      font-family: "Trebuchet MS", Helvetica, sans-serif;
      color: var(--global-palette3);
    }

    #main-menu {
      padding-top: 0.5rem;
      font-family: 'Source Sans Pro', sans-serif;

      li.active a.nav-link {
        border-bottom: 2px solid #777;
      }
    }

    #admin-menu {
      padding-right: 1rem;
    }
  }
}

main#root > .container {
  flex: 1 1 auto;
  background-color: $base-bgcolor;
  padding: 1.5rem 2rem;
  overflow-y: scroll;
}

/* HIGHLIGHTED CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.highlights .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.highlights h2 {
  font-weight: 400;
}
.highlights .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}
